<template>
  <div style="height: 100%">
    <BrandTest v-if="!this.$route.query.iframeGame" id="divMarathonTestWrapper"></BrandTest>
    <div v-if="this.$route.query.iframeGame" style="height: 100%">
      <div class="btn-container">
        <div class="btn-row">
          <VueButton
            class="back-btn"
            @click="onBackClick()"
            :iconName="btnBackIcon.name"
            :iconSize="{
              width: btnBackIcon.width,
              height: btnBackIcon.height,
            }"
            :iconColor="'#fff'"
            :ghost="true"
            target="-1"
            >Geri</VueButton
          >
        </div>
      </div>
      <iframe class="iframeGame" :src="iframeLink"></iframe>
    </div>
  </div>
</template>
<script>
import BrandTest from '@/components/brand/BrandTest/BrandTest.vue';
import GameApi from '@/services/Api/gamification';
import VueButton from '@/components/shared/VueButton/VueButton.vue';
import COMPONENT_CONSTANTS from '@/constants/component.constants.js';

export default {
  name: 'PlayAndWinDetail',
  components: {
    BrandTest,
    VueButton,
  },
  data() {
    return {
      iframeLink: null,
    };
  },
  computed: {
    btnBackIcon() {
      return COMPONENT_CONSTANTS.ICON_VARIABLES.arrowLeft;
    },
  },
  methods: {
    onBackClick() {
      this.$router.go(-1);
    },
  },
  beforeMount() {
    if (this.$route.query.iframeGame) {
      GameApi.getIframeGaming().then(res => {
        this.iframeLink = res.data.Data.iframe;
      });
    }
  },
};
</script>
<style lang="scss" scoped>
.iframeGame {
  width: 100%;
  height: 100%;
}
.btn-container {
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
}
.btn-row {
  max-width: 540px;
  margin: 0 auto;
  position: relative;
}
.back-btn {
  position: absolute;
  top: 25px;
  right: 0;
  color: #fff;
  background: #f58113;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 13vw;
  width: 27vw;
  max-width: 120px;
  max-height: 67px;
}
</style>
